/* eslint-disable vue/no-unused-vars */
<template>
  <div style="margin-left: 20px; margin-right: 20px">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Пакеты документов"
    />
    <a-table
      style="background-color: white"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :row-key="(record) => record.id"
    >
      <template v-slot:action="text, record">
        <a-tooltip
          placement="top"
          title="Редактировать"
          style="margin-right: 15px"
          ><a-button
            @click="
              $router.push({
                path: 'edit',
                name: 'documents.edit',
                params: { id: record.id, documentId: record.id },
              })
            "
            ><a-icon type="edit" /></a-button
        ></a-tooltip>

        <a-tooltip placement="top" title="Удалить пакет документов"
          ><a-button @click="deleteDocumentsPackage(text.id, text.name)"
            ><a-icon type="delete" /></a-button
        ></a-tooltip>
      </template>
    </a-table>
    <div>
      <a-form style="width: 500px">
        <a-form-item label="Добавить новый пакет документов">
          <a-textarea
            v-model="document_package_title"
            placeholder="Название пакета документов"
          ></a-textarea>
          <a-button @click="addDocumentsPackage">Добавить</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: 70,
  },
  {
    title: "Название",
    dataIndex: "name",
    width: 300,
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 20,
  },
];

const innerColumns = [
  {
    title: "id",
    dataIndex: "docs.id",
    key: "docs.id",
  },
  {
    title: "Название",
    dataIndex: "docs.title",
    key: "docs.title",
  },
  {
    title: "Action",
    dataIndex: "operation",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  data() {
    return {
      data: [],
      innerData: [],
      columns,
      innerColumns,
      loading: false,
      document_package_title: "",
    };
  },
  created() {
    this.getDocumentsList();
  },
  methods: {
    addDocumentsPackage() {
      let data = {
        name: this.document_package_title,
      };
      axios
        .post("cp/packages/", data)
        .then((response) => {
          console.log(response);
          this.getDocumentsList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteDocumentsPackage(package_id, package_name) {
      let data = {
        name: package_name,
      };
      axios
        .delete(`/cp/packages/${package_id}/`, data)
        .then((response) => {
          console.log(response);
          this.getDocumentsList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDocumentsList() {
      this.loading = true;
      axios
        .get("/cp/packages/")
        .then((response) => {
          this.data = response.data.data;
          this.innerData = response.data.data.docs;
          console.log(response.data.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
